import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Link, navigate } from "gatsby";
import Swal from "sweetalert2";

import { listPatients, sendEmailInvitation } from "~/src/api-client/patients";
import DashboardLayout from "~/src/components/DashboardLayout";
import { useContextUpdate, useContextValue } from "~/src/context";
import { User } from "~/src/types/models";

const PatientList = () => {
  const [patients, setPatients] = useState<User[]>([]);

  const { session, rehydrated, medic } = useContextValue();
  const { setPreLoadingStatus } = useContextUpdate();

  const fetchPatients = async (token: string) => {
    const patientsList = await listPatients(token);
    if (patientsList) {
      setPatients(patientsList.data);
    }
    setPreLoadingStatus(false);
  };

  const callInviteUser = () => {
    Swal.fire({
      title: "Invita a tu paciente",
      html: `<input type="text" id="patient_email" class="swal2-input" placeholder="Correo">
      <input type="text" id="patient_name" class="swal2-input" placeholder="Nombre">`,
      showCancelButton: true,
      confirmButtonText: "Enviar invitación",
      cancelButtonText: "Cancelar",
      preConfirm: async () => {
        const inviteData = {
          patient_email: Swal.getPopup()?.querySelector("#patient_email")?.value,
          patient_name: Swal.getPopup()?.querySelector("#patient_name")?.value,
        };
        if (session) {
          Swal.showLoading();
          const res = await sendEmailInvitation(session?.accessToken, inviteData);
          if (res) {
            Swal.fire({
              icon: "success",
              title: "Invitación Enviada",
            });
          }
        }
      },
    });
  };

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (medic && !medic.active) {
        navigate("/app");
      } else {
        fetchPatients(session?.accessToken);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated, medic, session]);

  useEffect(() => {
    setPreLoadingStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout
      header={
        <div
          className={clsx(
            "bg-white",
            "p-[20px] md:p-[40px]",
            "w-full",
            "border-b-[1px]",
            "border-gray-200",
            "flex items-center justify-between",
          )}
        >
          <div>
            <div className="text-xs md:text-md text-gray-500">Pacientes</div>
            <div className="text-lg md:text-3xl mt-[5px] font-bold">Listado de pacientes</div>
            <div className="text-xs md:text-md text-gray-400 mt-[5px]">Maneja su salud</div>
          </div>
          <div className="flex md:block flex-col">
            <button
              onClick={() => {
                callInviteUser();
              }}
              className="rounded-full bg-blue-700 p-[10px] md:p-[20px] text-xs md:text-sm text-white hover:bg-blue-500 hover:cursor-pointer"
            >
              Invitar Paciente
            </button>
            <Link to={`https://www.examedi.com/cl/registrarse/?medic=${medic?.id}`} target="_blank">
              <button className="mt-[10px] md:mt-[0px] md:ml-[20px] rounded-full bg-blue-700 p-[10px] md:p-[20px] text-xs md:text-sm text-white hover:bg-blue-500 hover:cursor-pointer">
                Registrar Paciente
              </button>
            </Link>
          </div>
        </div>
      }
    >
      <div className={clsx("relative", "bg-white", "w-[100%]", "rounded-lg border-gray-200", "drop-shadow-md", "mt-4")}>
        <table className="table-auto w-full text-left">
          <thead>
            <tr className="md:text-md text-xs">
              <th className="px-4 py-2">Nombre</th>
              <th className="px-4 py-2">Apellido Paterno</th>
              <th className="px-4 py-2 hidden md:table-cell">Apellido Materno</th>
              <th className="px-4 py-2 hidden md:table-cell">Correo</th>
              <th className="px-4 py-2 hidden md:table-cell">RUT</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y p-4">
            {patients?.map((patient: User, i: number) => (
              <tr key={i} className="hover:bg-gray-100">
                <td className="p-4 text-xs md:text-md">{patient.first_name}</td>
                <td className="p-4 text-xs md:text-md">{patient.last_name}</td>
                <td className="p-4 hidden md:table-cell">{patient.second_last_name}</td>
                <td className="p-4 hidden md:table-cell">{patient.email}</td>
                <td className="p-4 hidden md:table-cell">{patient.document_number}</td>
                <td className="p-4">
                  <Link to={`/app/patients/${patient.id}`}>
                    <button className="rounded-full bg-blue-700 py-[5px] px-[20px] text-xs md:text-sm text-white hover:bg-blue-500 hover:cursor-pointer">
                      Ver
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default PatientList;
